import { Checkbox, FormControl, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@type/promotion';

import { EffectiveDateTimeType } from '../../../effectiveDateTime/type/type';
import { useMutipleEffectiveDateTime } from '../../../effectiveDateTime';
import { DeliveredTypeSelector } from 'components/_dashboard/promotion/formik/promoDeliveredTypeSelector';
import { PromoMinimumAmount } from 'components/_dashboard/promotion/formik/PromoMinimumAmount';
import { PromotionType } from '__generated__/globalTypes';
import { Label } from 'components/_dashboard/promotion/styles';
import { canMoveProps } from '../../stepper/stepper';
import { voucherConditionCheck } from 'components/_dashboard/promotion/tools';

export interface VoucherConditionsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
}
export const VoucherConditions: React.FC<VoucherConditionsProps> = (props) => {
  const { t } = useTranslation();
  const { formik, setCanMoveFunc } = props;
  const promotionName = formik.values.name;
  const minimumAmount = formik.values.minimumAmount;
  const [isRequireMinimumAmountChecked, setIsRequireMinimumAmountChecked] = React.useState(
    // minimumAmount = string | number
    minimumAmount ? Number(minimumAmount) > 0 : false
  );
  const promoType = formik.values.type;
  const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
    formik.setFieldValue('effectiveDateTime', newList);
  };
  const { effectiveDateTime } = useMutipleEffectiveDateTime({
    dateTimesList: formik.values.effectiveDateTime,
    setDateTimeList: setEffectiveDateTime
  });

  const handleIsRequireMinimumAmountChecked = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('minimumAmount', 0);
    }
    setIsRequireMinimumAmountChecked(checked);
  };

  const canMove = useCallback(() => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      voucherConditionCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Typography fontWeight={'bold'} variant="h3" gutterBottom>
          {promotionName}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <DeliveredTypeSelector formik={formik} />
          </Grid>
          {promoType === PromotionType.percentage && (
            <>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: '100%' }}>
                  <FormControlLabel
                    value={isRequireMinimumAmountChecked}
                    checked={isRequireMinimumAmountChecked}
                    onChange={handleIsRequireMinimumAmountChecked}
                    control={<Checkbox />}
                    label="Require a minimum spend"
                    labelPlacement="end"
                  />
                </FormControl>
              </Grid>
              {isRequireMinimumAmountChecked && (
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Label>{t('Minimum Spend')}</Label>
                    <PromoMinimumAmount formik={formik} />
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {effectiveDateTime}
      </Stack>
    </FormControl>
  );
};
