import { FLOAT, FLOATING_POINT, INTEGER } from '@type/global';
import { regexFieldCheck } from './regexConfig';

export const checkVariables = (dataTemp: any) => {
  let finalTemp: any = {};
  Object.keys(dataTemp).forEach((item) => {
    if (dataTemp[item] !== '' && dataTemp[item] !== null) {
      finalTemp[item] = dataTemp[item];
    }
  });
  return finalTemp;
};

const checkPrices = (type: string, value: string) => {
  if (!regexFieldCheck(type, value)) {
    throw new Error('Price is invalid');
  }
};

export const convertValue = (type: string, value: string) => {
  switch (type) {
    case INTEGER:
      return Number(value);
    case FLOAT:
      return Number(value);
    case FLOATING_POINT:
      return Number(value);
    default:
      return value;
  }
};

export const checkAndConvert = (type: string, value: string): number => {
  checkPrices(type, value);
  switch (type) {
    case INTEGER:
      return Number(value);
    case FLOAT:
      return Number(value);
    case 'FLOATING_POINT':
      return Number(value);
    case 'INTEGER':
      return Number(value);
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
