import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import { Promotion } from '../../@type/promotion';
import { client } from 'index';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import { getValidPromotions } from 'components/_dashboard/promotion/tools';

// ----------------------------------------------------------------------

type PromotionState = {
  isLoading: boolean;
  error: boolean;
  promotionList: Promotion[];
  validPromotions: Promotion[];
};

const initialState: PromotionState = {
  isLoading: false,
  error: false,
  promotionList: [],
  validPromotions: []
};

const slice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPromotionList(state: any, action: any) {
      if (action.payload) {
        state.promotionList = action.payload;
      } else {
        state.promotionList = [];
      }
    },
    // GET PROMOTIONS
    getPromotionsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.promotionList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotionList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchPromotionList.fulfilled, (state, action) => {
        const promotions = action.payload;
        const validPromotions = getValidPromotions(promotions);
        state.promotionList = promotions;
        state.validPromotions = validPromotions;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchPromotionList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export const fetchPromotionList = createAsyncThunk(
  'promotion/fetchPromotionList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getPromotions: Promotion[] }>({
        query: GET_ALL_PROMOTIONS,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      const data = response.data.getPromotions as Promotion[];
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const { setPromotionList } = slice.actions;
