import { Box, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { dispatch, RootState, useSelector } from 'redux/store';
import { fetchPromotionList } from 'redux/slices/promotion';
import { PromotionType } from '__generated__/globalTypes';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { getPromoIds } from '../tools';

interface PromoTypeProp {
  formik: FormikProps<Promotion>;
  excludePromoTypes?: PromotionType[];
}

export const PromoSelector = (props: PromoTypeProp) => {
  const { formik, excludePromoTypes = [] } = props;
  const { setFieldValue, touched, errors } = formik;
  const { validPromotions, isLoading } = useSelector((state: RootState) => state.promotion);
  const [selectedPromo, setSelectedPromo] = useState<Promotion[]>([]);
  const { showSnackbar } = useSnackbarHelper();

  const getSelectedPromoIds = getPromoIds(selectedPromo);

  const handlePromoChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      // multiple select
      const selectedPromoIds = event.target.value as string[];
      const selectedPromoList = validPromotions.filter((promo) =>
        selectedPromoIds.includes(promo.id)
      );
      // check select promo type is in excludePromoTypes? if yes show error
      const isPromoTypeValid = selectedPromoList.every(
        (promo) => !excludePromoTypes?.includes(promo.type)
      );
      if (!isPromoTypeValid) {
        showSnackbar('The selected promotion type is not allowed.', 'error');
        return;
      }
      setSelectedPromo(selectedPromoList);
      const ids = getPromoIds(selectedPromoList);
      setFieldValue('resultingPromotions', ids);
    },
    [validPromotions, excludePromoTypes, showSnackbar, setFieldValue]
  );

  useEffect(() => {
    dispatch(fetchPromotionList());
  }, []);

  return (
    <>
      <Stack sx={{ marginBottom: '4px' }} direction="column">
        <Stack direction="row" spacing={2}>
          <Select
            name="resultingPromotions"
            multiple
            sx={{ width: '100%' }}
            value={getSelectedPromoIds}
            onChange={handlePromoChange}
            placeholder="Select Items"
            error={Boolean(touched.resultingPromotions && errors.resultingPromotions)}
          >
            <MenuItem value="placeholder" disabled>
              Select Promotions
            </MenuItem>
            {isLoading ? (
              <MenuItem value="placeholder" disabled>
                Loading...
              </MenuItem>
            ) : (
              validPromotions.map(
                (promo) =>
                  promo && (
                    <MenuItem
                      value={promo.id}
                      key={promo.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box component="span" sx={{ flexGrow: 1 }}>
                        {promo.name}
                      </Box>
                      {selectedPromo.some((sPromo) => sPromo.id === promo.id) && (
                        <Icon icon="material-symbols:close" />
                      )}
                    </MenuItem>
                  )
              )
            )}
          </Select>
        </Stack>
      </Stack>
    </>
  );
};
