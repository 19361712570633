import { Checkbox, FormControl, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Label } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@type/promotion';
import { PromotionType } from '__generated__/globalTypes';
import { PromotionTagsSelector } from '../tagsSelect';
import ItemButton from '../../../formik/itemButton';
import { PercentageSelector } from 'components/_dashboard/promotion/formik/percentageSelector';
import { PromoDiscountAmount } from 'components/_dashboard/promotion/formik/promoDiscountAmount';
import { PromoApplyDiscountToOptions } from 'components/_dashboard/promotion/formik/promoApplyDiscountToOptions';
import { PromoUpToAmount } from 'components/_dashboard/promotion/formik/prompUpToAmount';
import {
  DiscountType,
  useDiscountTypeSelector
} from 'components/_dashboard/promotion/formik/useDiscountTypeSelector';
import { PromoMinimumAmount } from 'components/_dashboard/promotion/formik/PromoMinimumAmount';
import { PromoDiscountedItemQty } from 'components/_dashboard/promotion/formik/promoDiscountedItemQty';
import { PromoBuyN } from 'components/_dashboard/promotion/formik/promoBuyN';
import { PromoFreeN } from 'components/_dashboard/promotion/formik/promoFreeN';
import { useQtyRestricted } from 'components/_dashboard/promotion/formik/useQtyRestricted';
import { canMoveProps } from '../../stepper/stepper';
import { voucherDetailsCheck } from 'components/_dashboard/promotion/tools';
import { PromoWinnerCount } from 'components/_dashboard/promotion/formik/PromoWinnerCount';
import { PromoPrizeDescription } from 'components/_dashboard/promotion/formik/PromoPrizeDescription';
import { PromoEntryLimitPerUser } from 'components/_dashboard/promotion/formik/PromoEntryLimitPerUser';
import { PromoIsEntryAutomatic } from 'components/_dashboard/promotion/formik/PromoIsEntryAutomatic';
import { PromoSelector } from 'components/_dashboard/promotion/formik/PromoSelector';
import { PromoIsPaymentRequired } from 'components/_dashboard/promotion/formik/promoIsPaymentRequired';

export interface VoucherDetailsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
  setFieldTouchedNameFunc: (name: string) => void;
}

export const VoucherDetails: React.FC<VoucherDetailsProps> = (props) => {
  const { t } = useTranslation();
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const {
    type,
    isPercentage,
    includedPromotionTags,
    excludedPromotionTags,
    includedGetItemsPromoTags
  } = values;

  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = useState(
    formik.values.excludedPromotionTags?.length
      ? formik.values.excludedPromotionTags?.length > 0
      : false
  );
  const [isUpToAmountChecked, setIsUpToAmountChecked] = useState(
    formik.values.upToAmount ? formik.values.upToAmount > 0 : false
  );

  const promotionName = formik.values.name;

  const { qtyRestrictedRadioGroup } = useQtyRestricted({
    formik,
    isOffer: false
  });
  const { discountTypeElement, discountType } = useDiscountTypeSelector({ formik });
  const handleIncludedPromotionTagsOnClick = (tag: string) => {
    // get an copy
    const tags = includedPromotionTags;
    if (tags && tags.includes(tag)) {
      // remove tag
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      formik.setFieldValue('includedPromotionTags', tags);
    }
  };
  const handleIncludedGetItemsPromoTagsOnClick = (tag: string) => {
    // get an copy
    const tags = includedGetItemsPromoTags;
    if (tags && tags.includes(tag)) {
      // remove tag
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      formik.setFieldValue('includedGetItemsPromoTags', tags);
    }
  };
  const handleExcludedPromotionTagsOnClick = (tag: string) => {
    // get an copy
    const tags = excludedPromotionTags;
    if (tags && tags.includes(tag)) {
      // remove tag
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      formik.setFieldValue('excludedPromotionTags', tags);
    }
  };

  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('excludedPromotionTags', []);
    }
    setIsExcludeTagsChecked(checked);
  };
  const handleUpToAmountCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('upToAmount', 0);
    }
    setIsUpToAmountChecked(checked);
  };

  const canMove = useCallback(() => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      if (formik.values.type === PromotionType.percentage) {
        setFieldTouchedNameFunc('minimumAmount');
        setFieldTouchedNameFunc('percentage');
        setFieldTouchedNameFunc('discountAmount');
      }
      if (formik.values.type === PromotionType.spend) {
        setFieldTouchedNameFunc('minimumAmount');
        setFieldTouchedNameFunc('discountedItemQty');
      }
      if (formik.values.type === PromotionType.buynGetFree) {
        setFieldTouchedNameFunc('buyN');
        setFieldTouchedNameFunc('freeN');
        setFieldTouchedNameFunc('minimumAmount');
      }
      if (formik.values.type === PromotionType.luckyDraw) {
        setFieldTouchedNameFunc('minimumAmount');
        setFieldTouchedNameFunc('winnerCount');
        setFieldTouchedNameFunc('entryLimitPerUser');
      }
      voucherDetailsCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values, setFieldTouchedNameFunc]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Typography fontWeight={'bold'} variant="h3" gutterBottom>
          {promotionName}
        </Typography>
        <Grid container spacing={1}>
          {type === PromotionType.luckyDraw && (
            <>
              <Grid item xs={12} md={12}>
                <>
                  <PromoPrizeDescription formik={formik} />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  <Label>{t('Minimum Amount')}</Label>
                  <PromoMinimumAmount formik={formik} />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  <Label>{t('Winner Count')}</Label>
                  <PromoWinnerCount formik={formik} />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  <Label>{t('Entry Limit')}</Label>
                  <PromoEntryLimitPerUser formik={formik} />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  <Label>{t('Promotions')}</Label>
                  <PromoSelector formik={formik} excludePromoTypes={[PromotionType.luckyDraw]} />
                </>
              </Grid>
              <Grid item xs={12} md={12}>
                <PromoIsEntryAutomatic formik={formik} />
              </Grid>
              <Grid item xs={12} md={12}>
                <PromoIsPaymentRequired formik={formik} />
              </Grid>
            </>
          )}
          {type === PromotionType.percentage && (
            <>
              <Grid item xs={12} md={6}>
                <>
                  <Label>{t('Minimum Amount')}</Label>
                  <PromoMinimumAmount formik={formik} />
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedPromotionTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedPromotionTags &&
                    includedPromotionTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedPromotionTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                <PercentageSelector formik={formik} isOffer={false} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PromoDiscountAmount formik={formik} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Applicable Items')}</Label>
                  <PromotionTagsSelector fieldString="includedGetItemsPromoTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedGetItemsPromoTags &&
                    includedGetItemsPromoTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedGetItemsPromoTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  value={isExcludeTagsChecked}
                  checked={isExcludeTagsChecked}
                  onChange={handleExcludedTagsCheckBoxOnChange}
                  control={<Checkbox />}
                  label="Exclude items from promotion"
                  labelPlacement="end"
                />
              </Grid>
              {isExcludeTagsChecked && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <Label>{t('Inapplicable Items')}</Label>
                      <PromotionTagsSelector fieldString="excludedPromotionTags" formik={formik} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                      {excludedPromotionTags &&
                        excludedPromotionTags.map((tag, index) => (
                          <div key={index}>
                            <ItemButton
                              onClick={() => handleExcludedPromotionTagsOnClick(tag)}
                              content={tag}
                            />
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <PromoApplyDiscountToOptions formik={formik} />
              </Grid>
              {isPercentage && (
                <>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      value={isUpToAmountChecked}
                      checked={isUpToAmountChecked}
                      onChange={handleUpToAmountCheckBoxOnChange}
                      control={<Checkbox />}
                      label="Limit total discount amount"
                      labelPlacement="end"
                    />
                  </Grid>
                  {isUpToAmountChecked && (
                    <Grid item xs={12} md={6}>
                      <PromoUpToAmount formik={formik} />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}

          {type === PromotionType.spend && (
            <>
              <Grid item xs={12} md={6}>
                <Label>{t('Spend Amount')}</Label>
                <PromoMinimumAmount formik={formik} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedPromotionTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedPromotionTags &&
                    includedPromotionTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedPromotionTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  value={isExcludeTagsChecked}
                  checked={isExcludeTagsChecked}
                  onChange={handleExcludedTagsCheckBoxOnChange}
                  control={<Checkbox />}
                  label="Exclude items from spend"
                  labelPlacement="end"
                />
              </Grid>
              {isExcludeTagsChecked && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <Label>{t('Excluded Items')}</Label>
                      <PromotionTagsSelector fieldString="excludedPromotionTags" formik={formik} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                      {excludedPromotionTags &&
                        excludedPromotionTags.map((tag, index) => (
                          <div key={index}>
                            <ItemButton
                              onClick={() => handleExcludedPromotionTagsOnClick(tag)}
                              content={tag}
                            />
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Get Free Item')}</Label>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <PromoDiscountedItemQty formik={formik} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedGetItemsPromoTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedGetItemsPromoTags &&
                    includedGetItemsPromoTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedGetItemsPromoTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>
            </>
          )}

          {type === PromotionType.buynGetFree && (
            <>
              <Grid item xs={12} md={12}>
                {discountTypeElement}
              </Grid>
              <Grid item xs={12} md={6}>
                {discountType === DiscountType.spend && (
                  <>
                    <Label>{t('Spend Amount')}</Label>
                    <PromoMinimumAmount formik={formik} />
                  </>
                )}
                {discountType === DiscountType.buyN && <PromoBuyN formik={formik} />}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedPromotionTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedPromotionTags &&
                    includedPromotionTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedPromotionTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  value={isExcludeTagsChecked}
                  checked={isExcludeTagsChecked}
                  onChange={handleExcludedTagsCheckBoxOnChange}
                  control={<Checkbox />}
                  label="Exclude items from buy"
                  labelPlacement="end"
                />
              </Grid>
              {isExcludeTagsChecked && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <Label>{t('Excluded Items')}</Label>
                      <PromotionTagsSelector fieldString="excludedPromotionTags" formik={formik} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                      {excludedPromotionTags &&
                        excludedPromotionTags.map((tag, index) => (
                          <div key={index}>
                            <ItemButton
                              onClick={() => handleExcludedPromotionTagsOnClick(tag)}
                              content={tag}
                            />
                          </div>
                        ))}
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Get Item')}</Label>
                  {qtyRestrictedRadioGroup}
                </FormControl>
              </Grid>
              <>
                <Grid item xs={12} md={6}>
                  <PromoFreeN formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Label>{t('Of what?')}</Label>
                    <PromotionTagsSelector
                      fieldString="includedGetItemsPromoTags"
                      formik={formik}
                    />
                  </FormControl>
                </Grid>
              </>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedGetItemsPromoTags &&
                    includedGetItemsPromoTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedGetItemsPromoTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
                <Grid item xs={12} md={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <Label>{t('Get Item Discount')}</Label>
                    <PercentageSelector formik={formik} isOffer={false} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PromoDiscountAmount formik={formik} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <PromoApplyDiscountToOptions formik={formik} />
              </Grid>
              {isPercentage && (
                <>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      value={isUpToAmountChecked}
                      checked={isUpToAmountChecked}
                      onChange={handleUpToAmountCheckBoxOnChange}
                      control={<Checkbox />}
                      label="Limit total discount amount"
                      labelPlacement="end"
                    />
                  </Grid>
                  {isUpToAmountChecked && (
                    <Grid item xs={12} md={6}>
                      <PromoUpToAmount formik={formik} />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Stack>
    </FormControl>
  );
};
