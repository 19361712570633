import {
  BuyNGetNFreePromotion,
  OfferVoucherSettings,
  PercentagePromotion,
  Promotion,
  SpendPromotion
} from '@type/promotion';
import {
  OrderType,
  PromotionStatus,
  PromotionType,
  PromotionUseType
} from '__generated__/globalTypes';
import { EffectiveDateTimeType, weekdayList } from './effectiveDateTime/type/type';
import moment from 'moment';
import { convertTime } from 'utils/helperTime';
import {
  CREATE_BUY_GET_FREE,
  CREATE_LUCKY_DRAW,
  CREATE_PERCENTAGE_DISCOUNT,
  CREATE_SPEND_PROMOTION,
  GET_BUY_GET_FREE_PROMOTION,
  GET_LUCKY_DRAW,
  GET_PERCENTAGE_PROMOTION,
  GET_SPEND_PROMOTION,
  UPDATE_LUCKY_DRAW,
  UPDATE_PERCENTAGE_DISCOUNT,
  UPDATE_SPEND_PROMOTION,
  UPDATE__BUY_GET_FREE
} from '_apis_/queries/promotion';

// interface OfferVoucherPromotion {
//   isOffer?: boolean;
//   offerVoucherSettings?: any;
// }

export function getInitialBuyNGetNPromotionState(): BuyNGetNFreePromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 0,
    discountAmount: 0,
    isPercentage: true,
    numberOfDeals: 10,
    availableType: [OrderType.dinein, OrderType.pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    buyN: 1,
    freeN: 1,
    includedPromotionTags: [],
    excludedPromotionTags: [],
    includedGetItemsPromoTags: [],
    applyOptionDiscount: true,
    status: PromotionStatus.draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isOffer: false,
    upToAmount: 0,
    useType: PromotionUseType.promotion
  };
}

export const initOfferVoucherSettings = () => {
  const offerVoucherSettings: OfferVoucherSettings = {
    offerName: '',
    offerDescription: '',
    effectiveHoursAfterOfferActivated: 12,
    effectiveDateTime: initEffectiveDateTime(),
    offerExcludedItemTags: [],
    minimumAmount: 0,
    upToAmount: 0,
    voucherTerms: ''
  };
  return offerVoucherSettings;
};

export function getInitialSpendPromotionState(): SpendPromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 100,
    discountAmount: 0,
    isPercentage: true,
    includedPromotionTags: [],
    excludedPromotionTags: [],
    numberOfDeals: 10,
    availableType: [OrderType.dinein, OrderType.pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    discountedItemQty: 1,
    applyOptionDiscount: true,
    status: PromotionStatus.draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: false,
    useType: PromotionUseType.promotion
  };
}

export function getInitialPercentagePromotionState(): PercentagePromotion {
  return {
    id: '',
    name: '',
    description: '',
    percentage: 0,
    discountAmount: 0,
    isPercentage: true,
    includedPromotionTags: [],
    numberOfDeals: 10,
    availableType: [OrderType.dinein, OrderType.pickup],
    minimumAmount: 0,
    effectiveDateTime: initEffectiveDateTime(),
    isPublished: true,
    canBeUsedWithOtherPromotions: false,
    applyOptionDiscount: true,
    status: PromotionStatus.draft,
    merchantId: '',
    usedQuantity: 0,
    createdDate: '',
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: false,
    useType: PromotionUseType.promotion
  };
}

export function getInitialPromotionState(
  promoType: PromotionType | undefined,
  isOffer?: boolean
): Promotion {
  const result: Promotion = {
    id: '',
    name: '',
    description: '',
    percentage: '',
    discountAmount: '',
    discountedItemQty: 1,
    isPercentage: true,
    includedPromotionTags: [],
    numberOfDeals: '',
    availableType: [OrderType.dinein, OrderType.pickup],
    minimumAmount: '',
    effectiveDateTime: initEffectiveDateTime(),
    canBeUsedWithOtherPromotions: false,
    applyOptionDiscount: true,
    status: PromotionStatus.draft,
    merchantId: '',
    usedQuantity: 0,
    isSignUpPromotion: false,
    upToAmount: 0,
    isOffer: isOffer ?? false,
    useType: PromotionUseType.promotion,
    type: promoType ?? PromotionType.percentage,
    buyN: 0,
    freeN: 1,
    isHiddenInApp: false
  };
  if (isOffer) {
    result.offerVoucherSettings = initOfferVoucherSettings();
  }
  if (promoType === PromotionType.buynGetFree) {
    result.minimumAmount = '';
    result.buyN = '';
    result.freeN = '';
  }
  if (promoType === PromotionType.spend) {
    result.minimumAmount = '';
    result.discountedItemQty = '';
    result.percentage = 100;
    result.isPercentage = true;
    result.discountAmount = 0;
  }
  if (promoType === PromotionType.luckyDraw) {
    result.winnerCount = '';
    result.entryLimitPerUser = '';
    result.isEntryAutomatic = false;
    result.resultingPromotions = [];
  }
  return result;
}

export const getQueryByPromoType = (promoTypeString: string | undefined) => {
  if (!promoTypeString || !(promoTypeString in PromotionType)) {
    throw new Error('Invalid promotion type');
  }
  const promoType = PromotionType[promoTypeString as keyof typeof PromotionType];
  switch (promoType) {
    case PromotionType.percentage:
      return {
        query: GET_PERCENTAGE_PROMOTION,
        create: CREATE_PERCENTAGE_DISCOUNT,
        update: UPDATE_PERCENTAGE_DISCOUNT,
        responseKey: 'getPercentagePromotion'
      };
    case PromotionType.spend:
      return {
        query: GET_SPEND_PROMOTION,
        create: CREATE_SPEND_PROMOTION,
        update: UPDATE_SPEND_PROMOTION,
        responseKey: 'getSpendPromotion'
      };
    case PromotionType.buynGetFree:
      return {
        query: GET_BUY_GET_FREE_PROMOTION,
        create: CREATE_BUY_GET_FREE,
        update: UPDATE__BUY_GET_FREE,
        responseKey: 'getBuyNGetNFreePromotion'
      };
    case PromotionType.luckyDraw:
      return {
        query: GET_LUCKY_DRAW,
        create: CREATE_LUCKY_DRAW,
        update: UPDATE_LUCKY_DRAW,
        responseKey: 'getLuckyDrawPromotion'
      };
    default:
      console.error('Unhandled promotion type: ', promoType);
      throw new Error('Invalid promotion type');
  }
};

// Spend $100 and get a voucher for your next visit
export const getOfferPromoName = (promo: Promotion): string => {
  // Spend $100 and get a voucher for your next visit
  let message = '';
  const minimumAmount = promo.minimumAmount;
  if (Number(minimumAmount) > 0) {
    message += `Spend $${minimumAmount} and `;
  }
  const buyN = promo.buyN;
  if (buyN && Number(buyN) > 0) {
    message += `Buy ${promo.buyN} items and `;
  }
  if (message === '') {
    message += 'Get ';
  } else {
    message += 'get ';
  }
  message += 'a voucher for your next visit';
  return message;
};

// spend: Get 1 free for every $50 spent
// amount/percentag: 50% off
// buyNgetN: Buy 1, get 1 free at 50% off
// buyNgetN: Spend $50, get 1 at 50% off
// buyNgetN: Buy 1, get infinite at 50% off
export const getVoucherPromoName = (promo: Promotion): string => {
  const { buyN, freeN, isPercentage, percentage, discountAmount, discountedItemQty, type } = promo;
  let minimumAmount = promo.minimumAmount;
  if (promo.isOffer) {
    minimumAmount = promo.offerVoucherSettings?.minimumAmount;
  }

  let message = '';
  if (type === PromotionType.luckyDraw) {
    // Spend $50, get XX for free.
    message = `Lucky Draw`;
    return message;
  }
  if (type === PromotionType.spend) {
    // Spend $50, get XX for free.
    message = `Get ${discountedItemQty} free for every $${minimumAmount} spent`;
  }
  if (type === PromotionType.buynGetFree) {
    // Simplify the construction of the message parts
    const buyNPart = buyN != null && Number(buyN) > 0 ? `Buy ${buyN}, ` : '';
    const minimumAmountPart =
      minimumAmount != null && Number(minimumAmount) > 0 ? `Spend $${minimumAmount}` : '';
    // Simplify the construction of the freeNPart
    let freeNPart = '';
    if (freeN !== undefined) {
      const freeNText = freeN === 0 || freeN === 'Infinite' ? 'all' : freeN;
      const discountText = isPercentage ? `${percentage}% off` : `$${discountAmount} off`;
      freeNPart = `Get ${freeNText} at ${discountText} `;
    }
    // Combine all parts
    const promotionParts = [buyNPart, minimumAmountPart, freeNPart]
      .filter((part) => part)
      .join(' ');
    message = promotionParts ? promotionParts.trim() : 'No promotion applicable';
  }
  if (type === PromotionType.percentage) {
    // 50% off
    if (isPercentage) {
      message = `${percentage}% off`;
    }
    if (!isPercentage) {
      message = `$${discountAmount} off`;
    }
  }
  return message;
};

export const deletePromotionVariables = <T extends { [key: string]: any }>(promotion: T) => {
  delete promotion.id;
  delete promotion.createdDate;
  delete promotion.merchantId;
  delete promotion.usedQuantity;
  delete promotion.status;
  return promotion;
};

export const removeTypenameKey = (obj: any): void => {
  if (obj === null || typeof obj !== 'object') {
    return;
  }
  if (Array.isArray(obj)) {
    obj.forEach((element) => removeTypenameKey(element));
    return;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (key === '__typename') {
      delete obj[key];
    } else {
      removeTypenameKey(value);
    }
  });
};

export const initEffectiveDateTime = (): EffectiveDateTimeType[] => {
  const result: EffectiveDateTimeType[] = [];
  const tempDateTime: EffectiveDateTimeType = {
    startDate: moment().format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
    hours: [
      {
        openStart: convertTime(moment().startOf('day'), 'time'),
        openEnd: convertTime(moment().endOf('day'), 'time')
      }
    ],
    recurringWeekDays: weekdayList
  };
  result.push(tempDateTime);
  return result;
};

export const updateOfferSetting = <T extends { [key: string]: any }>(promotion: T) => {
  if (!promotion.isOffer) {
    delete promotion.offerVoucherSettings;
    return promotion;
  }
  return promotion;
};

export const formatTimeFromMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

export const generateDateStrings = (dateTimes: EffectiveDateTimeType[]): string[] => {
  return dateTimes.map((dateTime) => {
    const startDate = moment(dateTime.startDate).format('DD/MM/YYYY');
    const endDate = moment(dateTime.endDate).format('DD/MM/YYYY');

    const timeStrings = dateTime.hours
      .map((hours) => {
        const start = formatTimeFromMinutes(hours.openStart);
        const end = formatTimeFromMinutes(hours.openEnd);
        return `${start} - ${end}`;
      })
      .join('  ');

    return `${startDate} - ${endDate}  ${timeStrings}`;
  });
};

export const convertPromoInput = (promotion: Promotion, isPublished: boolean) => {
  const baseObject = {
    name: promotion.name ?? '',
    description: promotion.description,
    excludedPromotionTags: promotion.excludedPromotionTags ?? [],
    includedPromotionTags: promotion.includedPromotionTags ?? [],
    includedGetItemsPromoTags: promotion.includedGetItemsPromoTags ?? [],
    numberOfDeals: Number(promotion.numberOfDeals) || 0,
    availableType: promotion.availableType,
    minimumAmount: Number(promotion.minimumAmount) || 0,
    isPercentage: promotion.isPercentage,
    percentage: Number(promotion.percentage) || 0,
    discountAmount: Number(promotion.discountAmount) || 0,
    effectiveDateTime: promotion.effectiveDateTime,
    isPublished: isPublished,
    canBeUsedWithOtherPromotions: promotion.canBeUsedWithOtherPromotions,
    applyOptionDiscount: promotion.applyOptionDiscount,
    isOffer: promotion.isOffer,
    offerVoucherSettings: promotion.offerVoucherSettings,
    upToAmount: Number(promotion.upToAmount),
    useType: promotion.useType,
    isSignUpPromotion: promotion.isSignUpPromotion,
    isHiddenInApp: promotion.isHiddenInApp || false
  };
  if (!promotion.numberOfDeals) {
    throw new Error('Number of Deals is required');
  }
  if (!Number.isFinite(Number(promotion.numberOfDeals)) || Number(promotion.numberOfDeals) <= 0) {
    throw new Error('Number of Deals must be greater than 0');
  }
  if (promotion.type === PromotionType.buynGetFree) {
    return {
      ...baseObject,
      buyN: Number(promotion.buyN) || 0,
      freeN: getFreeN(promotion)
    };
  }
  if (promotion.type === PromotionType.spend) {
    if (!promotion.minimumAmount || Number(promotion.minimumAmount) <= 0) {
      throw new Error('Spend Amount must be greater than 0');
    }
    if (!promotion.discountedItemQty) {
      throw new Error('Get Free Item Qty must be greater than 0');
    }
    return {
      ...baseObject,
      discountedItemQty: getDiscountedItemQty(promotion),
      isPercentage: true,
      percentage: 100
    };
  }
  if (promotion.type === PromotionType.percentage) {
    return {
      ...baseObject
    };
  }
  if (promotion.type === PromotionType.luckyDraw) {
    return {
      name: promotion.name ?? '',
      description: promotion.description,
      numberOfDeals: Number(promotion.numberOfDeals) || 0,
      availableType: promotion.availableType,
      minimumAmount: Number(promotion.minimumAmount) || 0,
      effectiveDateTime: promotion.effectiveDateTime,
      isPublished: isPublished,
      useType: promotion.useType,
      isSignUpPromotion: promotion.isSignUpPromotion,
      prizeDescription: promotion.prizeDescription,
      winnerCount: Number(promotion.winnerCount) || 0,
      entryLimitPerUser: Number(promotion.entryLimitPerUser) || 0,
      isEntryAutomatic: promotion.isEntryAutomatic,
      resultingPromotions: promotion.resultingPromotions,
      isPaymentRequired: promotion.isPaymentRequired ?? false
    };
  }
};

const getDiscountedItemQty = (promotion: Promotion) => {
  if (promotion.discountedItemQty === 'Infinite') {
    return 0;
  }
  if (promotion.discountedItemQty === undefined) {
    return 0;
  }
  if (typeof promotion.discountedItemQty === 'string') {
    if (!Number.isFinite(Number(promotion.discountedItemQty))) {
      throw new Error('Invalid number string');
    }
    if (Number(promotion.discountedItemQty) <= 0) {
      throw new Error('Get Free Item Qty must be greater than 0');
    }
    return Number(promotion.discountedItemQty);
  }
  if (typeof promotion.discountedItemQty === 'number') {
    if (promotion.discountedItemQty <= 0) {
      throw new Error('Get Free Item Qty must be greater than 0');
    }
    return promotion.discountedItemQty;
  }
  throw new Error('Invalid number string');
};
const getFreeN = (promotion: Promotion) => {
  if (promotion.freeN === 'Infinite') {
    return 0;
  }
  if (promotion.freeN === undefined) {
    return 0;
  }
  if (typeof promotion.freeN === 'string') {
    if (!Number.isFinite(Number(promotion.freeN))) {
      throw new Error('Invalid number string');
    }
    return Number(promotion.freeN);
  }
  if (typeof promotion.freeN === 'number') {
    return promotion.freeN;
  }
  throw new Error('Invalid number string');
};

const numberCheck = (
  title: string,
  value: number | string | 'Infinite' | undefined | null,
  isGreaterThanZeror: boolean,
  isInteger: boolean
) => {
  const isString = typeof value === 'string';
  if (isString) {
    if (value === 'Infinite') {
      return;
    }
    value = Number(value);
  }
  // check value type is number
  if (typeof value !== 'number') {
    throw new Error(`${title} must be a number`);
  }
  // check is NaN
  if (isNaN(value)) {
    throw new Error(`${title} must be a number`);
  }
  if (value < 0) {
    throw new Error(`${title} must be greater than or equal to 0`);
  }
  // check is greater than 0
  if (isGreaterThanZeror && value === 0) {
    throw new Error(`${title} must be greater than 0`);
  }
  if (isInteger && !Number.isInteger(value)) {
    throw new Error(`${title} must be an integer`);
  }
  const decimalPart = value.toString().split('.')[1];
  if (decimalPart && decimalPart.length > 2) {
    throw new Error(`${title} must have at most 2 decimal places`);
  }
};

const stringCheck = (title: string, value: string, isNotEmpty: boolean) => {
  if (typeof value !== 'string') {
    // title
    throw new Error(`${title} is not valid`);
  }
  if (isNotEmpty && value === '') {
    throw new Error(`${title} must not be empty`);
  }
};

export const promoNameValidation = (promo: Promotion) => {
  stringCheck('Name', promo.name, true);
};
export const promoDescriptionValidation = (promo: Promotion) => {
  stringCheck('Description', promo.description, true);
};
export const promoDiscountValidation = (promo: Promotion) => {
  if (promo.isPercentage) {
    numberCheck('Percentage', promo.percentage, true, false);
    return;
  }
  if (!promo.isPercentage) {
    numberCheck('Discount amount', promo.discountAmount, true, false);
    return;
  }
};

export const promoMinSpendBuyNValidation = (promo: Promotion) => {
  // if buyN = 0 and minSpend = 0, return
  if (!promo.buyN && !promo.minimumAmount) {
    throw new Error('Buy how many or Spend amount must be greater than 0');
  }
  if (promo.buyN === 0 && promo.minimumAmount === 0) {
    throw new Error('Buy how many or Spend amount must be greater than 0');
  }
  // if buyN = 0 and minSpend !== 0, minSpend is required
  if (promo.buyN === 0 && promo.minimumAmount !== 0) {
    numberCheck('Spend amount', promo.minimumAmount, true, false);
    return;
  }
  // if buyN !== 0 and minSpend = 0, buyN is required
  if (promo.buyN !== 0 && promo.minimumAmount === 0) {
    numberCheck('Buy how many', promo.buyN, true, true);
    return;
  }
};

export const promoDiscountItemQtyValidation = (promo: Promotion) => {
  numberCheck('Get how many', promo.discountedItemQty, true, true);
};

export const promoFreeNValidation = (promo: Promotion) => {
  numberCheck('Get how many', promo.freeN, true, true);
};

export const promoPublishDealsValidation = (promo: Promotion) => {
  numberCheck('Deals', promo.numberOfDeals, true, true);
};

export const promoMinimumAmountValidation = (promo: Promotion) => {
  numberCheck('Minimum amount', promo.minimumAmount, false, false);
};

export const promoWinnerCountValidation = (promo: Promotion) => {
  numberCheck('Winner Count', promo.winnerCount, true, true);
};

export const promoEntryLimitPerUserValidation = (promo: Promotion) => {
  numberCheck('Entry Limit', promo.entryLimitPerUser, true, true);
};
export const promoResultingPromotionsValidation = (promo: Promotion) => {
  const { resultingPromotions } = promo;
  // if resultingPromotions is undefined, throw error
  // if length of resultingPromotions is 0, throw error
  // !resultingPromotions includes undefined, null, 0, false, NaN, and an empty string "".
  if (!resultingPromotions) {
    throw new Error('Resulting Promotions is required');
  }
  if (resultingPromotions.length === 0) {
    throw new Error('Resulting Promotions is required');
  }
};

export const promoUpToAmountValidation = (promo: Promotion) => {
  numberCheck('Limit total discount amount', promo.upToAmount, false, false);
};

export const promoNameCheck = (promo: Promotion) => {
  promoNameValidation(promo);
};

export const promoEffectiveHoursAfterOfferActivatedValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  numberCheck(
    'Effective hours after offer activated',
    promo.offerVoucherSettings?.effectiveHoursAfterOfferActivated,
    false,
    true
  );
};

export const checkEffectiveDateTimeListValid = (
  effectiveDateTimeList: EffectiveDateTimeType[]
): boolean => {
  if (effectiveDateTimeList.length === 0) {
    throw new Error('Effective date time is required');
  }
  // const copyList = sortDateTime([...effectiveDateTimeList]);
  for (let i = 0; i < effectiveDateTimeList.length; i++) {
    const { startDate, endDate, hours } = effectiveDateTimeList[i];
    // Check that startDate is less than or equal to endDate
    if (new Date(startDate) > new Date(endDate)) {
      throw new Error(
        // startDate must be before or equal to endDate
        `${startDate} must be before or equal to ${endDate}.`
      );
    }
    // Iterate over each HoursType
    for (let j = 0; j < hours.length; j++) {
      const { openStart, openEnd } = hours[j];
      // Check that openStart is less than or equal to openEnd
      if (openStart > openEnd) {
        throw new Error(
          `${formatTimeFromMinutes(openStart)}  must be before or equal to  ${formatTimeFromMinutes(
            openEnd
          )}.`
        );
      }
      // Check that the previous element's openEnd is less than the current element's openStart
      if (j > 0 && new Date(hours[j - 1].openEnd) >= new Date(openStart)) {
        // openEnd must be before openStart
        throw new Error(
          `${formatTimeFromMinutes(openEnd)} must be before ${formatTimeFromMinutes(openStart)}.`
        );
      }
    }
    // Check that the previous element's endDate is less than the next element's startDate
    if (i > 0 && new Date(effectiveDateTimeList[i - 1].endDate) >= new Date(startDate)) {
      throw new Error(
        // previous endDate must be before current startDate
        `${effectiveDateTimeList[i - 1].endDate} must be before ${startDate}.`
      );
    }
  }
  return true; // Return true if all checks are passed
};

export const checkOfferVoucherEffectiveDateTimeValid = (
  offerList?: EffectiveDateTimeType[],
  nextVisitVoucherList?: EffectiveDateTimeType[]
) => {
  const offerDate = offerList && offerList.length > 0 ? findLatestDateTime(offerList) : new Date(0);
  const nextVisitVoucherDate =
    nextVisitVoucherList && nextVisitVoucherList.length > 0
      ? findLatestDateTime(nextVisitVoucherList)
      : new Date(0);
  if (offerDate > nextVisitVoucherDate) {
    throw new Error('Offer is valid up to or beyond the next visit voucher date.');
  }
};

export const promoOfferVoucherSettingNameValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  stringCheck('Name', promo.offerVoucherSettings.offerName, true);
};

export const promoOfferVoucherSettingsUpToAmountValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  console.log(promo.offerVoucherSettings.upToAmount);
  numberCheck('Limit total discount amount', promo.offerVoucherSettings.upToAmount, false, false);
};

export const promoOfferVoucherSettingsMinimumAmountValidation = (promo: Promotion) => {
  if (!promo.offerVoucherSettings) {
    throw new Error('Offer voucher settings is required');
  }
  numberCheck('Minimum amount', promo.offerVoucherSettings.minimumAmount, false, false);
};

export const baseInfoCheck = (promo: Promotion) => {
  // name is required
  // description is optional
  // numberOfDeals is required and greater than 0
  promoNameValidation(promo);
  promoPublishDealsValidation(promo);
};

export const voucherDetailsCheck = (promo: Promotion) => {
  // ----------- percentage/amount check-----------
  // if is percentage, percentage,upToAmount is required
  // if is amount, discount amount is required
  if (promo.type === PromotionType.percentage) {
    // check minSpend or buyN (at least one is required)
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
  }
  // ----------- buyNFreeN check -----------
  // check minSpend or buyN (at least one is required)
  // check freeN
  if (promo.type === PromotionType.buynGetFree) {
    // check minSpend or buyN (at least one is required)
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
    promoMinSpendBuyNValidation(promo);
    promoFreeNValidation(promo);
  }
  // ----------- spend check -----------
  // check minSpend
  // check discountItemQty
  if (promo.type === PromotionType.spend) {
    promoDiscountValidation(promo); // percentage and discount amount
    promoUpToAmountValidation(promo);
    promoMinimumAmountValidation(promo);
    promoDiscountItemQtyValidation(promo);
  }
  if (promo.type === PromotionType.luckyDraw) {
    // minimumAmount
    // winnerCount
    // entryLimitPerUser
    // resultingPromotions
    promoMinimumAmountValidation(promo);
    promoDiscountItemQtyValidation(promo);
    promoWinnerCountValidation(promo);
    promoEntryLimitPerUserValidation(promo);
    promoResultingPromotionsValidation(promo);
  }
};

export const voucherConditionCheck = (promo: Promotion) => {
  if (promo.type === PromotionType.percentage) {
    // check minSpend
    promoMinimumAmountValidation(promo);
  }
  // effectiveDateTime check
  if (!promo.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.effectiveDateTime);
};

export const offerDetailsCheck = (promo: Promotion) => {
  if (promo.type === PromotionType.buynGetFree) {
    // check minSpend or buyN (at least one is required)
    promoMinSpendBuyNValidation(promo);
  }
};

export const offerConditionCheck = (promo: Promotion) => {
  // minimumAmount
  // offerVoucherSetting.effectiveHoursAfterOfferActivated
  promoMinimumAmountValidation(promo);
  promoEffectiveHoursAfterOfferActivatedValidation(promo);
  if (!promo?.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.effectiveDateTime);
};

export const offerVoucherBaseInfoCheck = (promo: Promotion) => {
  promoOfferVoucherSettingNameValidation(promo);
};

export const offerVoucherDetailsCheck = (promo: Promotion) => {
  // discountedItemQty
  if (promo.type === PromotionType.spend) {
    promoMinimumAmountValidation(promo);
  }
  if (promo.type === PromotionType.buynGetFree) {
    promoFreeNValidation(promo);
  }
  promoDiscountValidation(promo); // percentage and discount amount
  promoUpToAmountValidation(promo);
};
export const offerVoucherConditionCheck = (promo: Promotion) => {
  // promo.offerVoucherSettings?.upToAmount check
  // promo.offerVoucherSettings?.minimumAmount check
  promoOfferVoucherSettingsUpToAmountValidation(promo);
  promoOfferVoucherSettingsMinimumAmountValidation(promo);
  if (!promo.offerVoucherSettings?.effectiveDateTime) {
    throw new Error('Effective date time is required');
  }
  checkEffectiveDateTimeListValid(promo.offerVoucherSettings?.effectiveDateTime);
  checkOfferVoucherEffectiveDateTimeValid(
    promo.effectiveDateTime,
    promo.offerVoucherSettings?.effectiveDateTime
  );
};

export const getValidPromotions = (promotions: Promotion[]): Promotion[] => {
  const currentDate = new Date();
  const validPromotions = promotions.filter((promotion) => {
    return promotion.effectiveDateTime.some((dateTime) => {
      const endDate = new Date(dateTime.endDate);
      endDate.setDate(endDate.getDate() + 1);
      return currentDate <= endDate;
    });
  });
  return validPromotions;
};

export const getPromoIds = (promoList: Promotion[]) => {
  return promoList.map((promo) => promo.id);
};

export const getPromoListByIds = (promotions: Promotion[], ids: string[]): Promotion[] => {
  return promotions.filter((promotion) => ids.includes(promotion.id));
};

export function parseDateAndTime(date: string, minutes: number): Date {
  const parts = date.split('-');
  if (parts.length !== 3) {
    throw new Error('Invalid date format. Please use "YYYY-MM-DD".');
  }

  const [year, month, day] = parts.map((num) => parseInt(num));
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error('Date contains non-numeric values.');
  }

  if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) {
    throw new Error('Date is out of valid range.');
  }

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (minutes < 0 || minutes >= 1440) {
    throw new Error('Minutes should be between 0 and 1439.');
  }
  const dateObj = new Date(year, month - 1, day, hours, mins);
  if (isNaN(dateObj.getTime())) {
    throw new Error('Invalid date computed from input values.');
  }
  return dateObj;
}

export function findLatestDateTime(dates?: EffectiveDateTimeType[]): Date {
  if (!dates || dates.length === 0) {
    return new Date(0);
  }
  let latestDate = new Date(0);
  dates.forEach((date) => {
    date.hours.forEach((hour) => {
      const currentDateTime = parseDateAndTime(date.endDate, hour.openEnd);
      if (currentDateTime > latestDate) {
        latestDate = currentDateTime;
      }
    });
  });
  return latestDate;
}

// function sortDateTime(dateTime: EffectiveDateTimeType[]) {
//   // Sort the main dateTime array by startDate
//   dateTime.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

//   // Sort each hours array by openStart within each dateTime element
//   dateTime.forEach((element) => {
//     element.hours.sort((a, b) => new Date(a.openStart).getTime() - new Date(b.openStart).getTime());
//   });
//   return dateTime;
// }
