import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties, SyntheticEvent } from 'react';

interface promoCustomerSegmentProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoCustomerSegment: React.FC<promoCustomerSegmentProps> = (props) => {
  const { formik, sx = {} } = props;
  const { isSignUpPromotion, isHiddenInApp } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleIsSignUpPromotionOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    formik.setFieldValue('isSignUpPromotion', checked);
  };

  const handleIsHiddenInAppOnChange = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
    formik.setFieldValue('isHiddenInApp', checked);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Customer Segment')}</Label>
        <FormControlLabel
          value={isSignUpPromotion}
          checked={isSignUpPromotion}
          onChange={handleIsSignUpPromotionOnChange}
          control={<Checkbox />}
          label={<Typography variant="body1">New customer sign up</Typography>}
          labelPlacement="end"
        />
        <FormControlLabel
          value={isHiddenInApp || false}
          checked={isHiddenInApp || false}
          onChange={handleIsHiddenInAppOnChange}
          control={<Checkbox />}
          label={<Typography variant="body1">Is hidden in App</Typography>}
          labelPlacement="end"
        />
        <FormControlLabel
          disabled={true}
          control={<Checkbox />}
          label={<Typography variant="body1">Returning customer</Typography>}
          labelPlacement="end"
        />
      </FormControl>
    </>
  );
};
